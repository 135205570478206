import _path from "path";
import _child_process from "child_process";
import _osTmpdir from "os-tmpdir";
import _osHomedir from "os-homedir";
import _process from "process";
var exports = {};
var process = _process;
var isWindows = process.platform === "win32";
var path = _path;
var exec = _child_process.exec;
var osTmpdir = _osTmpdir;
var osHomedir = _osHomedir; // looking up envs is a bit costly.
// Also, sometimes we want to have a fallback
// Pass in a callback to wait for the fallback on failures
// After the first lookup, always returns the same thing.

function memo(key, lookup, fallback) {
  var fell = false;
  var falling = false;

  exports[key] = function (cb) {
    var val = lookup();

    if (!val && !fell && !falling && fallback) {
      fell = true;
      falling = true;
      exec(fallback, function (er, output, stderr) {
        falling = false;
        if (er) return; // oh well, we tried

        val = output.trim();
      });
    }

    exports[key] = function (cb) {
      if (cb) process.nextTick(cb.bind(null, null, val));
      return val;
    };

    if (cb && !falling) process.nextTick(cb.bind(null, null, val));
    return val;
  };
}

memo("user", function () {
  return isWindows ? process.env.USERDOMAIN + "\\" + process.env.USERNAME : process.env.USER;
}, "whoami");
memo("prompt", function () {
  return isWindows ? process.env.PROMPT : process.env.PS1;
});
memo("hostname", function () {
  return isWindows ? process.env.COMPUTERNAME : process.env.HOSTNAME;
}, "hostname");
memo("tmpdir", function () {
  return osTmpdir();
});
memo("home", function () {
  return osHomedir();
});
memo("path", function () {
  return (process.env.PATH || process.env.Path || process.env.path).split(isWindows ? ";" : ":");
});
memo("editor", function () {
  return process.env.EDITOR || process.env.VISUAL || (isWindows ? "notepad.exe" : "vi");
});
memo("shell", function () {
  return isWindows ? process.env.ComSpec || "cmd" : process.env.SHELL || "bash";
});
export default exports;